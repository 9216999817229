<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="save-modal"
      hide-footer
      title="Kitchen Credential"
    >
      <div class="row">
        <div class="col-md-12">
          <b-form-group id="input-group-2" label="Email:" label-for="input-2">
            <b-form-input
              id="email"
              placeholder="Enter Email"
              v-model="email"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            id="input-group-2"
            label="User Name:"
            label-for="input-2"
          >
            <b-form-input
              id="phone"
              v-model="username"
              placeholder="Enter Phone"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form-group
            id="input-group-2"
            label="Password:"
            label-for="input-2"
          >
            <b-form-input
              id="phone"
              placeholder="Enter Password"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div style="float: right">
        <button
          ref="kt_login_signin_submit"
          type="button"
          class="btn btn-success mr-3"
        >
          save
        </button>
        <button type="button" class="btn btn-secondary" @click="hideModal">
          No
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  props: {
    email: {
      type: String,
    },
    username: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showKitchenDetailModal: this.show,
      modalBodyText: "",
    };
  },

  mounted() {
    this.$refs["save-modal"].show();
    this.showModel = true;
  },

  methods: {
    hideModal() {
      this.showModel = false;
      this.$refs["save-modal"].hide();
      this.$emit("closeModal", false);
    },

    deleteRecord() {
      try {
        const data = { id: this.userObj.item.id };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/user/restaurant/softDelete", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 200) {
            this.$refs["verfication-modal"].hide();
            this.$emit("closeModal", false);
            this.$emit("deleteRow", this.userObj);
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    commonAction() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.actionType == "delete") {
        this.deleteRecord();
      }
      if (this.actionType == "delete-resturant-image") {
        this.deleteRestaurantImage();
      }
      if (this.actionType == "delete-delivery-address") {
        this.deleteDeliveryAddress();
      }
      if (this.actionType == "active" || this.actionType == "deactive") {
        this.changeStatus();
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
</style>
